import common from "../../api/common";
import axios from "axios";
import axiosApiInstance from "../auth/authService";
const common_seamless_url = "http://stg-nextmed-backend-lb-247085592.us-east-2.elb.amazonaws.com/"

export const createSeamlesschexCustomer = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-create-customer-cc`,
    data: data,
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const checkSeamlesschexCustomerCard = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-create-authorize-cc`,
      data: data,
    };
  
    const resp = await axios(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const createSeamlesschexCustomer1 = (data) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-create-customer-cc`,
      data: data,
    };

    const resp = axios(config);
    if (resp && resp.data) {
      resolve(resp.data.data)
    } else {
      reject(null)
    }
  })
};

export const createSeamlesschexSales = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-create-sale-cc`,
    data: data,
  };
  const resp = await axios(config);
  if (resp && resp?.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const createOnetimeSeamlesschexSales = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-create-onetime-sale-cc`,
      data: data,
    };
    const resp = await axios(config);
    if (resp && resp?.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const getValuesFromMrn = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-information-for-billing-mrn`,
    data: data,
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getSubscriptionCustomer = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-get-subscriptions-by-customer`,
    data: data,
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const cancelSubscriptionCustomer = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-cancel-subscription-cc`,
    data: data,
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const cancelSubscriptionCustomerId = async (data) => {
  try {
    var config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-cancel-subscription-by-customer-id`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer" + " " + window.sessionStorage.getItem("token"),
      },
      data: data,
  
    };
    const resp = await axios(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
};

export const createSubscriptionCustomer = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-create-subscription-cc`,
    data: data,
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getSubscriptionIdByEmail_seamlesschex = async (payload) => {
  try {
    var config = {
      method: "post",
      url: `${common.api_url}/seamless-get-subscription-by-email`,
      data: payload,
    };
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
};

export const updateSeamlessPaymentMethod = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-update-payment-method`,
    data: payload,
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const validateCardForSeamless = async (payload) => {

  // example body = {
  //   "payment_token":"str",
  //   "first_name":"str",
  //   "last_name":"str"
  // }

  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-validate-card`,
    data: payload,
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};



export const createSubscription_seamless = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-create-subscription-cc`,
    data: payload,
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getLatestTransactionByEmail = async (payload) => {

  try {
    var config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-get-latest-transacctions-by-email`,
      data: payload,
    };

    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
};

export const cancelSubscripcionByEmail = async (payload) => {

  try {
    var config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-cancel-subscription-by-email`,
      data: payload,
    };

    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
};

export const validateCreditCardSeamlesschex = async (payload) => {
  try {
    var config = {
      method: "post",
      url: `${common.api_url}/seamlesschex-create-validate-cc`,
      data: payload,
    };

    const resp = await axios(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
};

export const getLastsTransactionsByEmail = async (payload) => {

  try {
    let config = {
      method: "post",
      url: `${common.api_url}/get-failed-transactions`,
      data: payload,
    };

    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
}

export const getMedicationTransaction = async (refund = false) => {
  try {
    let config = {
      method: "get",
      url: `${common.api_url}/get-last-three-months-transactions?email=${window.sessionStorage.getItem("email")}`,
    };

    if(refund){
      config.url = `${common.api_url}/get-last-three-months-transactions?email=${window.sessionStorage.getItem("email")}&refund=1`
    }

    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    if (error) return null
  }
}

export const getSubscriptionTransactions = async (refunded = false) => {

  try {
    
    let config = {
      method: "post",
      url:`${common.api_url}/refund-subcriptions-transaction`,
      data: {
        email: window.sessionStorage.getItem("email")
      }
    };
    
    if(refunded){
      config.url = `${common.api_url}/refund-subcriptions-transaction?refund=1`
    }

    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    }else{
      return null
    }

  } catch (error) {
    if (error) return null
  }
}